<template>
  <div>
    <lp-contacts-details work-type="수탁계좌" />
    <lp-contacts-details work-type="업무담당자" />
    <lp-contacts-details work-type="LP담당자" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LpContactsDetails        from '@/views/portfolio/LpContactsDetails.vue'

export default {
  name: 'LpContacts',
  components: {
    LpContactsDetails,
  },
  watch: {
    'drilldownPortfolio.share_code': {
      handler: function (newVal) {
        if (newVal) {
          this.refreshData()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
      'lpContacts',
    ]),
  },
  methods: {
    ...mapActions('portfolios', [
      'getLpContacts',
    ]),
    refreshData () {
      this.getLpContacts({share_code: this.drilldownPortfolio.share_code})
    }
  },
  mounted () {
    this.refreshData()
  }
}
</script>
