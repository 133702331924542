class CopyPasteHelpers {

  static convertClipboardToArrayOfArrays (data) {
    let rowReg = /\r?\n/g
    let rows = data.split(rowReg)
    let result = []
    rows.forEach(row => {
      result.push(row.split('\t'))
    })
    return result
  }

  static numToShortStringUnit (num, type='money') {
    let unitType = ''
    switch (type) {
      case 'money':
        unitType = '원'
        break
      case 'count':
        unitType = '명'
        break
      default:
        unitType = ''
    }

    let s = num.toString()
    s = s.replace(/^0+/, '')
    s = s.split('.')[0]
    if (s.length <= 4) {
      return  `${unitType}`
    } else if (s.length <= 5) {
      return `만${unitType}`
    } else if (s.length <= 8) {
      return `만${unitType}`
    } else {
      return `억`
    }
  }


}

export default CopyPasteHelpers
