<template>
  <div class='py-4'>
    <div class='sticky top-0 left-0 bg-white z-10' style='margin-left: -4rem; padding-left: 4rem;  margin-right: -4rem; padding-right: 4rem;'>
      <div>
        <h1 class='text-xl font-semibold uppercase'>
          {{ portfolioName }}
        </h1>
      </div>
      <div class='tab-container border-b mt-8 pl-8 flex flex-row justify-start text-sm text-gray-600 overflow-x-auto'>
        <div class='tab' :class='selectedTab("information")' @click='setActiveTab("information")'>INFORMATION</div>
        <div v-if='isLpUnion' class='tab' :class='selectedTab("contacts")' @click='setActiveTab("contacts")'>CONTACTS</div>
        <div class='tab' :class='selectedTab("files")' @click='setActiveTab("files")'>FILES</div>
        <div class='tab' :class='selectedTab("update")' @click='setActiveTab("update")'>UPDATE</div>
        <div class='tab' :class='selectedTab("profit")' @click='setActiveTab("profit")'>P&L</div>
        <div class='tab' :class='selectedTab("fundForm")' @click='setActiveTab("fundForm")'>PORTFOLIO</div>
      </div>
    </div>
    <component :is='tabContentComponent' class='mt-16' @done-editing='doneEditing' />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InvestmentHistory       from '@/views/dashboard/InvestmentHistory.vue'
import InvestmentFiles         from '@/views/dashboard/InvestmentFiles.vue'
import LpPortfolioInformations from '@/views/portfolio/LpPortfolioInformations.vue'
import LpProfitLoss            from '@/views/portfolio/LpProfitLoss.vue'
import LpProject               from '@/views/portfolio/LpProject.vue'
import LpBlind                 from '@/views/portfolio/LpBlind.vue'
import LpContacts              from '@/views/portfolio/LpContacts.vue'

export default {
  name: 'LpPortfolioDetails',
  components: {
    LpPortfolioInformations,
    InvestmentFiles,
    InvestmentHistory,
    LpProfitLoss,
    LpProject,
    LpBlind,
    LpContacts,
  },
  data () {
    return {
      activeTab: 'information',
    }
  },
  computed: {
    ...mapGetters('portfolios', [
      'lpPortfolioFundForm',
      'portfolioName',
    ]),
    isBlind () {
      return this.lpPortfolioFundForm === '블라인드'
    },
    isLpUnion () {
      return this.lpPortfolioFundForm === '조합'
    },
    tabContentComponent () {
      switch (this.activeTab) {
        case 'information':
          return 'lp-portfolio-informations'
        case 'contacts':
          return 'lp-contacts'
        case 'files':
          return 'investment-files'
        case 'update':
          return 'investment-history'
        case 'profit':
          return 'lp-profit-loss'
        case 'fundForm':
          if (this.isBlind) {
            return 'lp-blind'
          } else {
            return 'lp-project'
          }
        default:
          return 'information'
      }
    },
  },
  methods: {
    ...mapActions(['sidepanelClose']),
    doneEditing () {
      this.sidepanelClose()
    },
    setActiveTab (tab) {
      this.activeTab = tab
    },
    selectedTab (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
  },
  beforeDestroy () {
    this.sidepanelClose()
  },
}
</script>

<style lang='scss' scoped>
  .tab-container {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .tab {
    @apply uppercase tracking-wide px-8 pb-2 pt-4 cursor-pointer;
  }

  .tab:hover {
    @apply bg-gray-100;
  }

  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }
</style>
