<template>
  <div class='mt-8'>
    <table class='text-base'>
      <thead>
        <td colspan='3'>
          <button class='mt-8 text-xl hover:bg-gray-100 rounded-md border underline' @click='getLpHistorieGroups'>출자내역</button>
        </td>
        <td colspan='100' class='text-right'>(원)</td>
        <tr class='font-semibold bg-gray-100'>
          <td rowspan='2' class='px-2 py-2 text-center border'>출자일자</td>
          <td rowspan='2' class='px-2 py-2 text-center border'>출자금액</td>
          <td rowspan='2' class='px-2 py-2 text-center border'>약정총액 대비</td>
          <td :colspan='investmentDataCount' class='px-2 py-2 text-center border'>펀드별출자내역</td>
        </tr>
        <tr class='font-semibold bg-gray-100'>
          <td v-for='(row,  index) in investmentData' :key='`investmentDatum-${index}`'
            class='px-2 py-2 text-left border'>
            {{ row.short_name }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(investmentHistory, index) in investmentHistories'
          :key='investmentHistory.history_date'
          @click='selectHistoryRow(index)'
          :class='highlightRow(index)'>
          <td class='px-2 py-2 text-left border'>{{ investmentHistory.history_date }}</td>
          <td class='px-2 py-2 text-right border'>{{ toNumber(investmentHistory.sum_investment_value) }}</td>
          <td class='px-2 py-2 text-right border'>{{ contractRate(investmentHistory.sum_investment_value) }} %</td>
          <td v-for='(invValue, index) in investmentHistory.invValues' :key='index'
            class='px-2 py-2 text-right border'>
            {{ toNumber(invValue) }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class='font-semibold bg-gray-100'>
          <td class='px-2 py-2 text-left border text-center'>출자금액합계</td>
          <td class='px-2 py-2 text-right border'>{{ toNumber(totalInvestmentValue) }}</td>
          <td class='px-2 py-2 text-right border'>{{ contractRate(totalInvestmentValue) }} %</td>
          <td v-for='(idatum,  index) in investmentData' :key='`investmentValue-${index}`'
            class='px-2 py-2 text-right border'>
            {{ toNumber(idatum.fund_investment_value) }}
          </td>
        </tr>
        <tr class='font-semibold bg-gray-100'>
          <td class='px-2 py-2 text-center border'>약정총액</td>
          <td class='px-2 py-2 text-right border'> {{ toNumber(drilldownPortfolio.contract_value) }}</td>
          <td class='px-2 py-2 text-right border'>{{ totalContractRate() }} %</td>
          <td v-for='(row,  index) in investmentData' :key='`contractValue-${index}`'
            class='px-2 py-2 text-right border'>
            {{ toNumber(row.contract_value) }}
          </td>
        </tr>
        <tr class='bg-gray-100'>
          <td class='px-2 py-2 text-left border text-center'>미출자잔액</td>
          <td class='px-2 py-2 text-right border'>{{ toNumber(drilldownPortfolio.contract_value - totalInvestmentValue) }} </td>
          <td class='px-2 py-2 text-right border'>{{ (100 - contractRate(totalInvestmentValue)).toFixed(2) }} %</td>
          <td v-for='(investmentDatum,  index) in investmentData' :key='`remainValue-${index}`'
            class='px-2 py-2 text-right border'>
            {{ toNumber(calcFundRemainValue(index)) }}
          </td>
        </tr>
        <tr>
          <td colspan='100' class='text-right py-4'>
            <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
              @click='copyToClipboard'>Copy To Clipboard</button>
          </td>
        </tr>
      </tfoot>
    </table>

    <table class='text-base mt-8'>
      <thead>
        <td colspan='4' class='text-left text-xl underline'>분배내역</td>
        <td colspan='100' class='text-right'>(원)</td>
        <tr class='font-semibold bg-gray-100'>
          <td rowspan='2' class='px-2 py-2 text-center border'>분배일자</td>
          <td rowspan='2' class='px-2 py-2 text-center border'>분배총액</td>
          <td rowspan='2' class='px-2 py-2 text-center border'>원금</td>
          <td rowspan='2' class='px-2 py-2 text-center border'>이익</td>
          <td :colspan='distributionFundCount' class='px-2 py-2 text-center border'>펀드별 원금 분배내역</td>
          <td :colspan='distributionFundCount' class='px-2 py-2 text-center border'>펀드별 이익 분배내역</td>
        </tr>
        <tr class='font-semibold bg-gray-100'>
          <td v-for='(row,  index) in distributionData' :key='`$principalFund-${index}`'
            class='px-2 py-2 text-left border'>
            {{ row.short_name }}
          </td>
          <td v-for='(row,  index) in distributionData' :key='`$profitFund-${index}`'
            class='px-2 py-2 text-left border'>
            {{ row.short_name }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for='distribution_history in distributionHistories'
          :key='`distribution_history-${distribution_history.history_date}`'>
          <td class='px-2 py-2 text-left border'>{{ distribution_history.history_date }}</td>
          <td class='px-2 py-2 text-right border'>{{ toNumber(distribution_history.sum_distribution_value) }}</td>
          <td class='px-2 py-2 text-right border'>{{ toNumber(distribution_history.sum_principal) }}</td>
          <td class='px-2 py-2 text-right border'>{{ toNumber(distribution_history.sum_profit) }}</td>
          <td v-for='(priValue,  index) in distribution_history.priValues' :key='`principal-${index}`'
            class='px-2 py-2 text-right border'>
            {{ toNumber(priValue) }}
          </td>
          <td v-for='(proValue,  index) in distribution_history.proValues' :key='`profit-${index}`'
            class='px-2 py-2 text-right border'>
            {{ toNumber(proValue) }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class='font-semibold bg-gray-100'>
          <td class='px-2 py-2 text-left border-t border-b border-l border-r text-center'>분배금액합계</td>
          <td class='px-2 py-2 text-right border-t border-b border-r'>{{ toNumber(totalPrincipal + totalProfit) }}</td>
          <td class='px-2 py-2 text-right border-t border-b border-r'>{{ toNumber(totalPrincipal) }}</td>
          <td class='px-2 py-2 text-right border-t border-b border-r'>{{ toNumber(totalProfit) }}</td>
          <td v-for='(dDatum,  index) in distributionData' :key='`principal-${index}`'
            class='px-2 py-2 text-right border-r border-b border-t'>
            {{ toNumber(dDatum.fund_principal) }}
          </td>
          <td v-for='(dDatum,  index) in distributionData' :key='`profit-${index}`'
            class='px-2 py-2 text-right border-r border-b border-t'>
            {{ toNumber(dDatum.fund_profit) }}
          </td>
        </tr>
        <tr>
          <td class='px-2 py-2 text-left'>잔여원금</td>
          <td></td>
          <td class='px-2 py-2 text-right'> {{ toNumber(totalInvestmentValue - totalPrincipal) }}</td>
        </tr>
      </tfoot>
    </table>

    <lp-calc-profit class='mt-2'/>

    <div class='py-4'>
      <label class='text-xl'>
        <input type='checkbox' class='form-checkbox mr-1 px-2' v-model='displayEdit' >
          출자/분배 내역 입력 보기
        </label>
      <lp-history-edit v-if='displayEdit' />
      <lp-portfolio-fund-edit v-if='displayEdit' />
    </div>
  </div>
</template>

<script>
import { mapState }             from 'vuex'
import numbro                   from 'numbro'
import LpPortfoliosApi          from '@/api/v1/lp_portfolios'
import LpCalcProfit             from '@/views/portfolio/LpCalcProfit.vue'
import LpHistoryEdit            from '@/views/portfolio/LpHistoryEdit.vue'
import LpPortfolioFundEdit      from '@/views/portfolio/LpPortfolioFundEdit.vue'

export default {
  name: 'LpProfitLoss',
  components: {
    LpHistoryEdit,
    LpCalcProfit,
    LpPortfolioFundEdit,
  },
  data () {
    return {
      displayEdit: false,
      investmentHistories: [],
      firstIndex: -1,
      secondIndex: -1,
      distributionHistories: [],
      result: '',
      totalInvestmentValue: 0,
      totalProfit: 0,
      totalPrincipal: 0,
      totalContractValue: 0,
      investmentData: [],
      distributionData: [],
    }
  },
  watch: {
    'drilldownPortfolio.share_code': {
      handler: function (newCode) {
        if (newCode.length > 5) {
          this.getLpHistorieGroups ()
          this.result = ''
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
    investmentDataCount () {
      return this.investmentData.length
    },
    distributionFundCount () {
      return this.distributionData.length
    },
  },
  methods: {
    toNumber (number) {
      if (!number)
        return 0
      if (parseInt(number) < 1000 && parseInt(number) > -1000)
        return parseInt(number)

      return numbro(parseInt(number)).format({ thousandSeparated: true })
    },
    highlightRow (index) {
      return index >= this.firstIndex && index <= this.secondIndex ? 'bg-blue-100 border-b' : 'border-b'
    },
    selectHistoryRow (index) {
      if (event.shiftKey) {
        if (this.firstIndex === -1) {
          this.firstIndex = index
          this.secondIndex = index
        } else {
          if (index < this.firstIndex) {
            this.secondIndex = this.firstIndex
            this.firstIndex = index
          } else {
            this.secondIndex = index
          }
        }
      } else {
        this.firstIndex = index
        this.secondIndex = index
      }
    },
    copyToClipboard () {
      let string = ''
      string = this.tableHeaderString ()
      string += this.convertDataToString ()
      this.$copyText(string).then(() => {
        this.$message({
          type: 'success',
          message: `클립보드 복사 완료`
        })
      })
    },
    tableHeaderString () {
      let string = ''
      string += '출자일자' + '\t'
      string += '출자금액' + '\t'
      string += '약정총액대비(%)' + '\t'
      this.investmentData.forEach(row => { string += row.short_name + '\t' })
      string += '\n'

      return string
    },
    convertDataToString() {
      let string = ''

      this.investmentHistories.forEach(row => {
        string += row.history_date + '\t'
        string += this.toNumber(row.sum_investment_value) + '\t'
        string += this.contractRate(row.sum_investment_value) + '\t'

        row.invValues.forEach((invValue) => { string += this.toNumber(invValue) + '\t' })

        string += '\n'
      })

      return string
    },
    totalContractRate () {
      if (!this.drilldownPortfolio.contract_value || this.drilldownPortfolio.contract_value === 0)
        return 0
      else
        return (this.totalContractValue * 100 / this.drilldownPortfolio.contract_value).toFixed(2)
    },
    getLpHistorieGroups () {
      LpPortfoliosApi.getLpHistorieGroups({share_code: this.drilldownPortfolio.share_code, history_type:'출자내역'}).then( resp => {
        this.investmentHistories = resp
        this.setInvestmentData ()
        this.calcTotalInvestmentValue ()
      })
      LpPortfoliosApi.getLpHistorieGroups({share_code: this.drilldownPortfolio.share_code, history_type:'분배내역'}).then( resp => {
        this.distributionHistories = resp
        this.setDistributionData ()
        this.calcTotalDistributionValue ()
      })
    },
    setInvestmentData () {
      this.investmentData = []
      this.totalContractValue = 0
      this.investmentHistories.forEach(row => {
        row.investment_rows.forEach(col => {
          if (this.investmentData.findIndex(row => row.short_name === col.short_name) === -1) {
            this.investmentData.push({short_name: col.short_name, contract_value: col.contract_value, fund_investment_value: 0})
            this.totalContractValue += col.contract_value
          }
        })
      })

      this.investmentHistories.forEach((row, row_index) => {
        let invValues = []
        for (var i = 0; i < this.investmentData.length; i++) {
          invValues.push(0)
        }
        row.investment_rows.forEach(col => {
          var fundIdx = this.investmentData.findIndex(row => row.short_name === col.short_name)
          if (fundIdx > -1) {
            invValues[fundIdx] = col.investment_value
          }
        })
        this.investmentHistories[row_index].invValues = invValues
      })
    },
    setDistributionData () {
      this.distributionData = []
      this.distributionHistories.forEach(row => {
        row.distribution_rows.forEach(col => {
          if (this.distributionData.findIndex(row => row.short_name === col.short_name) === -1) {
            this.distributionData.push({ short_name: col.short_name, fund_profit: 0, fund_principal: 0 })
          }
        })
      })

      this.distributionHistories.forEach((row, row_index) => {
        let proValues = []
        let priValues = []
        for (var i = 0; i < this.distributionData.length; i++) {
          proValues.push(0)
          priValues.push(0)
        }
        row.distribution_rows.forEach(col => {
          var fundIdx = this.distributionData.findIndex(row => row.short_name === col.short_name)
          if (fundIdx > -1) {
            proValues[fundIdx] = col.profit
            priValues[fundIdx] = col.principal
          }
        })
        this.distributionHistories[row_index].proValues = proValues
        this.distributionHistories[row_index].priValues = priValues
      })
    },
    calcTotalInvestmentValue () {
      this.totalInvestmentValue = 0
      this.investmentHistories.forEach(row => {
        this.totalInvestmentValue += parseInt(row.sum_investment_value)
        row.invValues.forEach((invValue, index) => {
          this.investmentData[index].fund_investment_value += parseInt(invValue)
        })
      })
    },
    calcTotalDistributionValue () {
      this.totalProfit = 0
      this.totalPrincipal = 0
      this.distributionHistories.forEach(row => {
        this.totalProfit += parseInt(row.sum_profit)
        this.totalPrincipal += parseInt(row.sum_principal)
        row.proValues.forEach((proValue, index) => {
          this.distributionData[index].fund_profit += parseInt(proValue)
        })
        row.priValues.forEach((priValue, index) => {
          this.distributionData[index].fund_principal += parseInt(priValue)
        })
      })
    },
    contractRate (investment_value) {
      if (!this.drilldownPortfolio.contract_value)
        return 0
      if (this.drilldownPortfolio.contract_value == 0)
        return 0
      return (investment_value * 100 / this.drilldownPortfolio.contract_value).toFixed(2)
    },
    calcFundRemainValue (index) {
      if (!this.investmentData[index])
        return 0
      if (!this.investmentData[index].contract_value)
        return 0
      if (!this.investmentData[index].fund_investment_value)
        return this.investmentData[index].contract_value

      return this.investmentData[index].contract_value - this.investmentData[index].fund_investment_value
    },
  },
}
</script>
