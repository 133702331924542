<template>
  <div>
    <div class='flex flex-row my-1 mt-4'>
      <h4 class='text-xl'>펀드별 정보 입력</h4>
      <button
        class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-lp-portfolio-edit-form-btn'
        @click='addNewLpPortfolio'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
    </div>
    <table class='text-base'>
      <thead class='font-semibold bg-gray-100'>
        <th class='px-2 py-2 text-center border-r border-l border-b border-t'>펀드명</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>약정금액</th>
      </thead>
      <tbody>
        <tr v-for='(lp_portfolio, index) in lp_portfolios' :key='index' @change='lp_portfolio.is_change="Y"' >
          <td class='px-2 border-r border-l border-b'>
            <input type='text'
              v-model='lp_portfolio.short_name'
              @paste.prevent='onPasteData($event)'
              class='w-full text-left' />
          </td>
          <td class='px-2 border-r border-b'>
            <input type='numeric' class='w-full text-right'
              v-bind:value='toNumber(lp_portfolio.contract_value)'
              v-on:input='updateContractValue(lp_portfolio)'>
          </td>
        </tr>
      </tbody>
    </table>
    <div class='flex'>
      <button class='py-2 font-bold text-left border rounded-md border-transparent hover:border-gray-500'
        @click='uploadLpPortfolios'>Save Changes: </button>
      <label @click='result=""' class='px-4 py-2 inline-block text-left'>{{ result }}</label>
    </div>
  </div>
</template>

<script>
import { mapState }       from 'vuex'
import numbro             from 'numbro'
import LpPortfoliosApi    from '@/api/v1/lp_portfolios'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import CopyPasteHelpers   from '@/utils/copy-paste-helpers'

export default {
  name: 'LpPortfolioFundEdit',
  components: {
    PlusCircleIcon,
  },
  data () {
    return {
      lp_portfolios: [],
      result : '',
    }
  },
  watch: {
    'drilldownPortfolio.share_code': {
      handler: function (newCode) {
        if (newCode.length > 5) {
          this.getLpPortfolios ()
          this.result = ''
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
  },
  methods: {
    toNumber (number) {
      if (number) {
        return numbro(this.uncomma(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    uncomma (str) {
      str = String (str)
      return str.replace (/[^(\d.)]+/g, '')
    },
    getLpPortfolios () {
      LpPortfoliosApi.getLpPortfolios({share_code: this.drilldownPortfolio.share_code}).then( resp => {
        this.lp_portfolios = resp
      })
    },
    uploadLpPortfolios () {
      this.result = ''
      LpPortfoliosApi.updateLpPortfolios({ share_code: this.drilldownPortfolio.share_code,
                                           lp_params: this.lp_portfolios.filter((row) => row.is_change && row.is_change === 'Y') }).then( resp => {
        this.result = resp
        this.getLpPortfolios ()
      })
    },
    addNewLpPortfolio () {
      this.lp_portfolios.push ({ id: this.lp_portfolios.length, contract_value: 0 })
    },
    updateContractValue (lp_portfolio) {
      var updateNumeric = event.target.value
      lp_portfolio.contract_value = updateNumeric
    },
    onPasteData (data) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      pastedArray.forEach((dataArray) => {
        var lpIndex =  this.lp_portfolios.findIndex( (datum) => datum.short_name ===  dataArray[0])
        if ( lpIndex === -1 ) {
          this.addNewLpPortfolio ()
          lpIndex = this.lp_portfolios.length -1
        }
        this.lp_portfolios[lpIndex].short_name = dataArray[0]
        this.lp_portfolios[lpIndex].contract_value = dataArray[1]
        this.lp_portfolios[lpIndex].is_change = 'Y'
      })
    },
  },
  mounted () {
    this.getLpPortfolios ()
  },
}
</script>
