<template>
  <div class='py-4'>
    <div class='flex flex-row my-1 py-4'>
      <h4 class='text-2xl'>블라인드</h4>
      <button
        class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-lp-blind-data-form-btn'
        @click='addNewLpBlind'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
    </div>
    <table class='text-base w-full table-auto'>
      <thead class='font-semibold bg-gray-100'>
        <th class='px-2 py-2 text-center border-r border-l border-b border-t'>투자기업명</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>투자원금</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>투자단가</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>투자밸류</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>사업내용</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>투심보고서</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>IR자료</th>
      </thead>
      <tbody>
        <tr v-for='(lp_blind, index) in lp_blinds' :key='`lp_blind-${lp_blind.id}`'>
          <td class='px-2 border-r border-l border-b w-48'>
            <input type='text' v-model='lp_blind.investor' class='text-left w-full' />
          </td>
          <td class='px-2 border-r border-b w-32'>
            <input type='numeric' class='text-right w-full'
              v-bind:value='toNumber(lp_blind.investment_principal)'
              v-on:input='updateInvestmentPricipal(lp_blind)'>
          </td>
          <td class='px-2 border-r border-b w-32'>
            <input type='numeric' class='text-right w-full'
              v-bind:value='toNumber(lp_blind.investment_price)'
              v-on:input='updateInvestmentPrice(lp_blind)'>
          </td>
          <td class='px-2 border-r border-b w-32'>
            <input type='text' v-model='lp_blind.market_investment_value' class='text-right w-full' />
          </td>
          <td class='px-2 border-r border-b'>
            <textarea type='text' v-model='lp_blind.major_business'
              class='text-left w-full rounded-md pl-4 py-1 pr-32 h-16 leading-tight'
              placeholder='사업내용입력'>
            </textarea>
          </td>
          <td class='px-2 border-r border-b'>
            <div class='flex flex-row overflow-wrap leading-10 w-full'>
              <span>
                <a :href='lp_blind.report_file_url' download target='_blank'>{{lp_blind.report_file}}</a>
              </span>
              <label class='inline-block py-1 px-2 text-blue-800 flex-grow h-12 leading-10 cursor-pointer hover:bg-gray-200'>
                [파일선택]
                <input @change='reportFileSelect(index)' type='file' :ref='`ReportFile-${index}`' class='bg-pink-200 hidden'>
              </label>
            </div>
          </td>
          <td class='px-2 border-r border-b'>
            <div class='flex flex-row overflow-wrap leading-10 w-full'>
              <span>
                <a :href='lp_blind.ir_file_url' download target='_blank'>{{lp_blind.ir_file}}</a>
              </span>
              <label class='inline-block py-1 px-2 text-blue-800 flex-grow h-12 leading-10 cursor-pointer hover:bg-gray-200'>
                [파일선택]
                <input @change='irFileSelect(index)' type='file' :ref='`IrFile-${index}`' class='bg-pink-200 hidden'>
              </label>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class='font-semibold'>
          <td class='px-2 py-2 text-left border-t border-l border-r border-b text-center'>합계</td>
          <td class='px-2 py-2 text-right border-t border-r border-b '>{{ toNumber(sumInvestmentPrincipal) }}</td>
          <td class='px-2 py-2 text-right border-t border-r border-b'></td>
          <td class='px-2 py-2 text-right border-t border-r border-b'></td>
          <td class='px-2 py-2 text-right border-t border-r border-b'></td>
          <td class='px-2 py-2 text-right border-t border-r border-b'></td>
          <td class='px-2 py-2 text-right border-t border-r border-b'></td>
        </tr>
      </tfoot>
    </table>
    <div class='flex'>
      <button class='py-2 font-bold text-left border rounded-md border-transparent hover:border-gray-500'
        @click='uploadLpBlinds'>Save Changes: </button>
      <label @click='result=""' class='px-4 py-2 inline-block text-left'>{{result}}</label>
    </div>
  </div>
</template>

<script>
import { mapState }       from 'vuex'
import LpBlindsApi        from '@/api/v1/lp_portfolios'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import numbro             from 'numbro'

export default {
  name: 'LpBlind',
  components: {
    PlusCircleIcon,
  },
  data () {
    return {
      lp_blinds: [],
      uploadBlinds: [],
      result: '',
      sumInvestmentPrincipal: 0,
    }
  },
  watch: {
    'drilldownPortfolio.share_code': {
      handler: function (newCode) {
        if (newCode.length > 5) {
          this.getLpBlinds ()
          this.result = ''
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
  },
  methods: {
    toNumber (number) {
      if (number) {
        return numbro(this.uncomma(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    uncomma (str) {
      str = String (str)
      return str.replace (/[^(\d.)]+/g, '')
    },
    getLpBlinds () {
      LpBlindsApi.getLpBlinds({share_code: this.drilldownPortfolio.share_code}).then( resp => {
        this.lp_blinds = resp
        this.calcSumInvestmentPrincipal ()
      })
    },
    uploadLpBlinds () {
      this.result = ''

      let formData = new FormData ()

      formData.append('share_code', this.drilldownPortfolio.share_code)

      this.lp_blinds.forEach((lp_blind) => {
        if (lp_blind.investor && lp_blind.investor.length > 0) {
          formData.append('investors[]', lp_blind.investor)
          formData.append('investment_principals[]', lp_blind.investment_principal)
          formData.append('investment_prices[]', lp_blind.investment_price)
          formData.append('market_investment_values[]', lp_blind.market_investment_value)
          formData.append('major_businesses[]', lp_blind.major_business)
          formData.append('touch_report_files[]', lp_blind.touch_report_file)
          formData.append('touch_ir_files[]', lp_blind.touch_ir_file)
          formData.append('report_files[]', lp_blind.report_file_input)
          formData.append('ir_files[]', lp_blind.ir_file_input)
        }
      })

      LpBlindsApi.createLpBlinds(formData).then( resp => {
        this.result = resp
        this.getLpBlinds ()
      })
    },
    calcSumInvestmentPrincipal () {
      this.sumInvestmentPrincipal = 0
      this.lp_blinds.forEach(row => { this.sumInvestmentPrincipal += parseInt(row.investment_principal) })
    },
    addNewLpBlind () {
      this.lp_blinds.push ({id: this.lp_blinds.length, investor: '', investment_principal: 0, investment_price: 0,
                            market_investment_value: '', major_business: '',
                            report_file: '', ir_file: '',
                            touch_report_file: 'N', touch_ir_file: 'N',
                            report_file_input: null, ir_file_input: null})
    },
    updateInvestmentPricipal (lp_blind) {
      var updateNumeric = event.target.value
      lp_blind.investment_principal = updateNumeric
    },
    updateInvestmentPrice (lp_blind) {
      var updateNumeric = event.target.value
      lp_blind.investment_price = updateNumeric
    },
    reportFileSelect (index) {
      this.lp_blinds[index].report_file_input = this.$refs[`ReportFile-${index}`][0].files[0]
      this.lp_blinds[index].report_file = this.$refs[`ReportFile-${index}`][0].files[0].name
      this.lp_blinds[index].touch_report_file = 'Y'
    },
    irFileSelect (index) {
      this.lp_blinds[index].ir_file_input = this.$refs[`IrFile-${index}`][0].files[0]
      this.lp_blinds[index].ir_file = this.$refs[`IrFile-${index}`][0].files[0].name
      this.lp_blinds[index].touch_ir_file = 'Y'
    },
  },
}
</script>
