<template>
  <div class='py-4'>
    <div class='flex flex-row my-1 py-4'>
      <h4 class='text-2xl'>LP정보</h4>
      <button
        class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-lp-information-data-form-btn'
        @click='addNewLpInformation'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
    </div>
    <table class='text-base'>
      <thead class='font-semibold bg-gray-100'>
        <th class='px-2 py-2 text-center border-r border-l border-b border-t'>출자자명</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>약정총액</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>약정비율</th>
        <th class='px-2 py-2 text-center border-r border-b border-t'>비고</th>
      </thead>
      <tbody>
        <tr v-for='(lp_information, index) in lp_informations' :key='`lp_information-${lp_information.id}`'>
          <td class='px-2 border-r border-l border-b w-2/3'>
            <input type='text' v-model='lp_information.investor'
              class='w-full text-left'
              @paste.prevent='onPasteData($event, index)' />
          </td>
          <td class='px-2 border-r border-b'>
            <input type='numeric' class='w-full text-right'
              v-bind:value='toNumber(lp_information.contract_value)'
              v-on:input='updateLpContractValue(lp_information)'>
          </td>
          <td class='px-2 border-r border-b'>
            <div class='flex flex-row'>
              <input type='numeric' v-model='lp_information.contract_rate' class='w-full text-right' />
              <label class='px-2 py-2'>%</label>
            </div>
          </td>
          <td class='px-2 border-r border-b'>
            <input type='text' v-model='lp_information.gp_or_lp' class='w-full text-left' />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class='font-semibold'>
          <td class='px-2 py-2 text-left border-t border-l border-r border-b text-center'>합계</td>
          <td class='px-2 py-2 text-right border-t border-r border-b '>{{ toNumber(sumContractValue) }}</td>
          <td class='px-2 py-2 text-right border-t border-r border-b'></td>
          <td class='px-2 py-2 text-right border-t border-r border-b'></td>
        <tr>
        </tr>
      </tfoot>
    </table>
    <div class='flex'>
      <button class='py-2 font-bold text-left border rounded-md border-transparent hover:border-gray-500'
        @click='uploadLpInformations'>Save Changes: </button>
      <label @click='result=""' class='px-4 py-2 inline-block text-left'>{{result}}</label>
    </div>
  </div>
</template>

<script>
import { mapState }       from 'vuex'
import numbro             from 'numbro'
import LpPortfoliosApi    from '@/api/v1/lp_portfolios'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import CopyPasteHelpers   from '@/utils/copy-paste-helpers'

export default {
  name: 'LpInformations',
  components: {
    PlusCircleIcon,
  },
  data () {
    return {
      lp_informations: [],
      result: '',
      sumContractValue: 0,
    }
  },
  watch: {
    'drilldownPortfolio.share_code': {
      handler: function (newCode) {
        if (newCode.length > 5) {
          this.getLpInformations ()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
  },
  methods: {
    toNumber (number) {
      if (number) {
        return numbro(this.uncomma(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    uncomma (str) {
      str = String (str)
      return str.replace (/[^(\d.)]+/g, '')
    },
    getLpInformations () {
      LpPortfoliosApi.getLpInformations({share_code: this.drilldownPortfolio.share_code}).then( resp => {
        this.lp_informations = resp
        this.calcSumContractValue ()
      })
    },
    uploadLpInformations () {
      this.result = ''
      LpPortfoliosApi.createLpInformations({share_code: this.drilldownPortfolio.share_code, lp_informations: this.lp_informations.filter(inf => inf.investor.length > 0)}).then( resp => {
        this.result = resp
        this.getLpInformations ()
      })
    },
    calcSumContractValue () {
      this.sumContractValue = 0
      this.lp_informations.forEach(row => {
        this.sumContractValue += parseInt(row.contract_value)
      })
    },
    addNewLpInformation () {
      this.lp_informations.push ({id: this.lp_informations.length, share_code: this.drilldownPortfolio.share_code, investor: '', contract_value: 0, contract_rate: 0, gp_or_lp: ''})
    },
    updateLpContractValue (lp_information) {
      var updateNumeric = event.target.value
      lp_information.contract_value = updateNumeric
    },
    onPasteData (data, targetIndex) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      let remainLength = this.lp_informations.length - targetIndex

      let ii
      for (ii = 0; ii <= pastedArray.length - remainLength; ii++) {
        this.addNewLpInformation ()
      }

      pastedArray.forEach((row, index) => {
        this.updateRow(targetIndex + index, row)
      })

      if (ii > 0) {
        this.lp_informations.pop()
      }
    },
    updateRow (rowIndex, dataArray) {
      let index = 0

      this.lp_informations[rowIndex].share_code = this.drilldownPortfolio.share_code
      this.lp_informations[rowIndex].investor = dataArray[index++]
      this.lp_informations[rowIndex].contract_value = dataArray[index++]
      this.lp_informations[rowIndex].contract_rate = dataArray[index++]
      this.lp_informations[rowIndex].gp_or_lp = dataArray[index++]
    },
  },
}
</script>
