<template>
  <div>
    <h2 class='text-xl underline'>펀드기본정보</h2>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>펀드명</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioName'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>구분</label>
      <v-select
        v-model='drilldownPortfolioFundForm'
        label='펀드명'
        :options='fundFormOptions'
        :close-on-select='true'
        class='w-1/5'>
      </v-select>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>기초자산</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioBaseAssetName'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>펀드코드</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioShareCode'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>설립일자</label>
      <el-date-picker
        v-model='drilldownPortfolioStartDate'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='설립일자'>
      </el-date-picker>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>존속기간</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioExistenceTerm'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>만기일자</label>
      <el-date-picker
        v-model='drilldownPortfolioEndDate'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='만기일자'>
      </el-date-picker>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>결성총액</label>
      <input
        class='form-input w-1/5 text-right'
        v-bind:value='toNumber(drilldownPortfolioOrganizationValue)'
        v-on:input='updateOrganizationValue'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>당사약정총액</label>
      <input
        class='form-input w-1/5 text-right'
        v-bind:value='toNumber(drilldownPortfolioContractValue)'
        v-on:input='updateContractValue'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>GP</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioGp'>
    </div>
    <div class='flex'>
      <button
        class='font-bold border rounded-md border-transparent hover:border-gray-500'
        @click='submitLpShareCode'>Save Changes : </button>
      <label @click='result=""' class='px-4 inline-block'>{{result}}</label>
    </div>

    <lp-informations />

  </div>
</template>

<script>
import { mapFields }                        from 'vuex-map-fields'
import { mapGetters, mapActions, mapState } from 'vuex'
import LpPortfoliosApi                      from '@/api/v1/lp_portfolios'
import LpInformations                       from '@/views/portfolio/LpInformations.vue'
import numbro                               from 'numbro'


export default {
  name: 'LpPortfolioInformations',
  components: {
    LpInformations,
  },
  props: [
    'displayInModal'
  ],
  data () {
    return {
      showConfirmDelete: false,
      portfolio: {},
      result: '',
    }
  },
  computed: {
    ...mapState('investments', [
      'fundFormOptions',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
    ...mapFields('portfolios', {
      drilldownPortfolioName: 'drilldownPortfolio.name',
      drilldownPortfolioBaseAssetName: 'drilldownPortfolio.base_asset_name',
      drilldownPortfolioFundForm: 'drilldownPortfolio.fund_form',
      drilldownPortfolioShareCode: 'drilldownPortfolio.share_code',
      drilldownPortfolioStartDate: 'drilldownPortfolio.start_date',
      drilldownPortfolioEndDate: 'drilldownPortfolio.end_date',
      drilldownPortfolioExistenceTerm: 'drilldownPortfolio.existence_term',
      drilldownPortfolioOrganizationValue: 'drilldownPortfolio.organization_value',
      drilldownPortfolioContractValue: 'drilldownPortfolio.contract_value',
      drilldownPortfolioGp: 'drilldownPortfolio.gp',
    }),
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    submitLpShareCode () {
      this.result = ''
      LpPortfoliosApi.patchLpShareCode( this.drilldownPortfolio ).then(resp => {
        this.result = resp
      })
    },
    toNumber (number) {
      if (number) {
        return numbro(this.uncomma(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    uncomma (str) {
      str = String (str)
      return str.replace (/[^(\d.)]+/g, '')
    },
    updateContractValue () {
      var updateNumeric = event.target.value
      this.drilldownPortfolioContractValue = updateNumeric
    },
    updateOrganizationValue () {
      var updateNumeric = event.target.value
      this.drilldownPortfolioOrganizationValue = updateNumeric
    },
  },
}
</script>
