<template>
  <div>
    <button class='mt-8 text-xl hover:bg-gray-100 rounded-md border underline' @click='getCalcLpProfit'>수익률계산</button>
    <div class='flex text-base leading-10'>
      <label class='inline-block w-64'>기분배총액</label>
      <label class='inline-block w-32 text-right'>{{ toNumber(lp_profit.sum_profit_principal) }} </label>
    </div>
    <div class='flex text-base leading-10'>
      <label class='inline-block w-64'>잔여원금</label>
      <label class='inline-block w-32 text-right'>{{ toNumber(lp_profit.remain_principal) }} </label>
    </div>
    <div class='flex text-base leading-10'>
      <label class='inline-block w-64'>미회수자산 평가차익(예상)</label>
      <input class='form-input w-32 text-right'
        v-bind:value='toNumber(lp_profit.unrealized_gain)'
        v-on:input='updateUnrealizedGain(lp_profit)'>
    </div>
    <div class='flex text-base leading-10'>
      <label class='inline-block w-64'>총 평가액</label>
      <label class='inline-block w-32 text-right'>{{ toNumber(lp_profit.total_profit) }} </label>
    </div>
    <div class='flex text-base leading-10'>
      <label class='inline-block w-64'>출자총액</label>
      <label class='inline-block w-32 text-right'>{{ toNumber(lp_profit.total_investment) }} </label>
    </div>
    <div class='flex text-base leading-10'>
      <label class='inline-block w-64'>단순수익률</label>
      <label class='inline-block w-32 text-right'>{{ lp_profit.profit_rate }} % </label>
    </div>
    <div class='flex text-base leading-10'>
      <label class='inline-block w-64'>IRR</label>
      <label class='inline-block w-32 text-right'>{{ lp_profit.xirr }} % </label>
    </div>
    <div class='flex'>
      <button
        class='font-bold border rounded-md border-transparent hover:border-gray-500'
        @click='submitLpShareCode'>Save Changes : </button>
      <label class='inline-block px-2' @click='result=""'>{{ result }}</label>
    </div>
  </div>
</template>

<script>
import numbro                    from 'numbro'
import { mapActions, mapState }  from 'vuex'
import { mapFields }             from 'vuex-map-fields'
import LpPortfoliosApi           from '@/api/v1/lp_portfolios'

export default {
  name: 'LpCalcProfit',
  props: [
    'displayInModal'
  ],
  data () {
    return {
      showConfirmDelete: false,
      result: '',
      lp_profit: '',
    }
  },
  watch: {
    'drilldownPortfolio.share_code': {
      handler: function (newCode) {
        if (newCode.length > 5)  {
          this.getCalcLpProfit ()
          this.result = ''
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
    ...mapFields('portfolios', {
      drilldownPortfolioUnrealizedGain: 'drilldownPortfolio.unrealized_gain',
    }),
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    submitLpShareCode () {
      this.result = ''
      this.drilldownPortfolioUnrealizedGain = this.lp_profit.unrealized_gain
      LpPortfoliosApi.patchLpShareCode( this.drilldownPortfolio ).then(resp => {
        this.result = resp
      })
    },
    getCalcLpProfit () {
      LpPortfoliosApi.getLpProfit({ share_code: this.drilldownPortfolio.share_code }).then(resp => {
        this.lp_profit = resp[0]
      })
    },
    toNumber (number) {
      if (number) {
        return numbro(this.uncomma(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    uncomma (str) {
      str = String (str)
      return str.replace (/[^(\d.)]+/g, '')
    },
    updateUnrealizedGain (lp_profit) {
      var updateNumeric = event.target.value
      lp_profit.unrealized_gain = updateNumeric
    },
  },
}
</script>
