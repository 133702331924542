<template>
  <div>
    <div class='flex flex-row items-center my-1 py-4'>
      <h4 class='text-2xl'>{{ workType }}</h4>
      <button
        class='px-2 border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-lp-information-data-form-btn'
        @click='addLpContact'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
    </div>
    <table v-if="workType === '수탁계좌'" class='text-xs'>
      <thead class='font-semibold bg-gray-100'>
        <tr>
          <th class='px-2 py-2 border'>구분</th>
          <th class='px-2 py-2 border'>은행/증권사</th>
          <th class='px-2 py-2 border'>계좌번호/ID</th>
          <th class='px-2 py-2 border'>비밀번호</th>
          <th class='px-2 py-2 border'>계좌주</th>
          <th class='px-2 py-2 border'>비고</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='contact in filteredContacts' :key='contact.id'>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.role'
              class='form-input w-24 text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.corporate_name'
              class='form-input w-28 text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.account_number'
              class='form-input text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.password'
              class='form-input text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.account_owner'
              class='form-input w-48 text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.note'
              class='form-input w-48 text-xs' />
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else class='text-xs'>
      <thead class='font-semibold bg-gray-100'>
        <tr>
          <th class='px-2 py-2 text-center border-r border-l border-b border-t'>담당</th>
          <th class='px-2 py-2 text-center border-r border-b border-t'>법인명</th>
          <th class='px-2 py-2 text-center border-r border-b border-t'>담당자</th>
          <th class='px-2 py-2 text-center border-r border-b border-t'>사무실</th>
          <th class='px-2 py-2 text-center border-r border-b border-t'>핸드폰</th>
          <th class='px-2 py-2 text-center border-r border-b border-t'>이메일</th>
          <th class='px-2 py-2 text-center border-r border-b border-t'>주소</th>
          <th class='px-2 py-2 text-center border-r border-b border-t'>비고</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='contact in filteredContacts' :key='contact.id'>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.role'
              class='form-input w-24 text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.corporate_name'
              class='form-input text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.contact_name'
              class='form-input text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.office_phone_number'
              class='form-input text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.mobile_phone_number'
              class='form-input text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.email'
              class='form-input text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.address'
              class='form-input w-56 text-xs' />
          </td>
          <td class='border-r border-l border-b'>
            <input type='text' v-model='contact.note'
              class='form-input w-48 text-xs' />
          </td>
        </tr>
      </tbody>
    </table>
    <div class='flex flex-row items-center'>
      <button class='py-2 font-bold text-left border rounded-md border-transparent hover:border-gray-500'
        @click='updateLpContacts'>Save Changes: </button>
      <label @click='result=""' class='px-2 py-2 inline-block text-left'>{{result}}</label>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import { PlusCircleIcon }    from '@vue-hero-icons/outline'

export default {
  name: 'LpContactsDetails',
  components: {
    PlusCircleIcon,
  },
  props: [
    'workType',
  ],
  data () {
    return {
      result: '',
    }
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
    ...mapMultiRowFields('portfolios', [
      'lpContacts',
    ]),
    filteredContacts () {
      return this.lpContacts ? this.lpContacts.filter(lpContact => lpContact.work_type === this.workType) : []
    },
  },
  methods: {
    ...mapActions('portfolios', [
      'patchLpContacts',
    ]),
    ...mapMutations('portfolios', [
      'addNewLpContact',
    ]),
    addLpContact () {
      let filter = {workType: this.workType, shareCode: this.drilldownPortfolio.share_code}
      this.addNewLpContact(filter)
    },
    updateLpContacts () {
      this.result = ''
      this.patchLpContacts({shareCode: this.drilldownPortfolio.share_code, workType: this.workType}).then(() => {
        this.$message({
          type: 'success',
          message: '저장 완료'
        })
        this.result = '저장 완료'
      })
    },
  },
}
</script>
