<template>
  <th class='relative font-semibold' :rowspan='headerRowspan' :colspan='headerColspan'>
    <div
      class='whitespace-no-wrap pr-8'>
      {{ defaultLabel }}
      <component
        :is='sortIcon'
        class='h-4 text-gray-600'
        :class='showSortIcon' />
      <component
        :is='clearIcon'
        @click='clearFilterValue'
        class='cursor-pointer text-gray-600 hover:text-gray-900'
        :class='showClearIcon' />
    </div>
    <el-popover
      v-model='showFilterDropdown'
      placement='bottom'
      width='360'>
      <div class='relative'>
        <el-radio-group v-model='sortingDirValue' size='small' @change='updateSortInfo'>
          <el-radio-button label='asc'></el-radio-button>
          <el-radio-button label='desc'></el-radio-button>
        </el-radio-group>
        <v-select v-model='filterValue'
          multiple
          :options='optionLists'
          :close-on-select='false' >
        </v-select>
      </div>
      <div slot='reference'
        class='absolute'
        style='top: 0.5rem; right: 0.5rem;'>
        <filter-icon
          class='inline-block h-4 w-4 rounded cursor-pointer text-gray-600 hover:text-gray-800 opacity-25 hover:opacity-75 hover:bg-gray-200'
          :class='hasFilterStyle' />
      </div>
    </el-popover>
  </th>
</template>

<script>
import { FilterIcon,
         SortAscendingIcon,
         SortDescendingIcon,
         XCircleIcon,
       } from '@vue-hero-icons/outline'

export default {
  name: 'TableHeaderFilterSelect',
  components: {
    FilterIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    XCircleIcon,
  },
  props: [
    'defaultLabel',
    'filterTexts',
    'sortingDir',
    'sortingKey',
    'keyName',
    'rowspanNum',
    'colspanNum',
    'optionObject',
  ],
  data () {
    return {
      filterValue: this.filterTexts,
      sortingDirValue: this.sortingDir,
      showFilterDropdown: false,
      showSelect: true,
    }
  },
  watch: {
    filterValue: function () {
      this.updateFilter()
    }
  },
  computed: {
    optionLists () {
      return this.getDistinct()
    },
    headerRowspan () {
      return this.rowspanNum ? this.rowspanNum : 1
    },
    headerColspan () {
      return this.colspanNum ? this.colspanNum : 1
    },
    hasFilterValue () {
      return this.filterValue.length > 0
    },
    hasFilterStyle () {
      return (this.hasFilterValue) ? 'opacity-100 text-blue-800' : ''
    },
    showSortIcon () {
      return (this.sortingDirValue === '') ? 'hidden' : 'inline-block'
    },
    showClearIcon () {
      return (this.hasFilterValue) ? 'inline-block' : 'hidden'
    },
    sortIcon () {
      switch (this.sortingDirValue) {
        case 'asc':
        return 'sort-ascending-icon'
        case 'desc':
        return 'sort-descending-icon'
        case '':
        default:
        return 'span'
      }
    },
    clearIcon () {
      return 'x-circle-icon'
    }
  },
  methods: {
    clearFilterValue () {
      this.filterValue = []
    },
    updateFilter () {
      this.$emit('update:filterTexts', this.filterValue)
    },
    updateSortInfo () {
      this.$emit('update:sortingDir', this.sortingDirValue)
      this.$emit('update:sortingKey', this.keyName)
    },
    getDistinct () {
      let distinctLists = []
      this.optionObject.forEach((element) => {
        if (distinctLists.indexOf (element[this.keyName]) === -1 && element[this.keyName])
          distinctLists.push(element[this.keyName])
      })

      return distinctLists
    },
  }
}
</script>
