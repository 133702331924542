<template>
  <div>
    <h2 class='text-xl underline'>프로젝트</h2>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>기초자산</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioBaseAssetName'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>사업내용</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioLpBusiness'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>투자단가</label>
      <input class='form-input w-1/5 text-right' v-model='drilldownPortfolioLpPrice'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>투자밸류</label>
      <input class='form-input w-1/5 text-right' v-model='drilldownPortfolioLpValue'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>투자원금</label>
      <input class='form-input w-1/5 text-right' v-model='drilldownPortfolioLpPrincipal'>
    </div>
    <div class='flex leading-10 text-base py-1'>
      <label class='inline-block w-32'>주요조건</label>
      <input class='form-input w-1/5' v-model='drilldownPortfolioMajorCondition'>
    </div>
    <div class='flex'>
      <button
        class='py-4 font-bold border rounded-md border-transparent hover:border-gray-500'
        @click='submitLpShareCode'>Save Changes : </button>
      <label class='py-4 px-2 inline-block' @click='result=""'>{{ result }}</label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { mapFields }                        from 'vuex-map-fields'
import LpPortfoliosApi                      from '@/api/v1/lp_portfolios'

export default {
  name: 'LpProject',
  props: [
    'displayInModal'
  ],
  data () {
    return {
      showConfirmDelete: false,
      result: '',
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
    ...mapFields('portfolios', {
      drilldownPortfolioBaseAssetName: 'drilldownPortfolio.base_asset_name',
      drilldownPortfolioLpBusiness: 'drilldownPortfolio.lp_business',
      drilldownPortfolioLpPrice: 'drilldownPortfolio.lp_price',
      drilldownPortfolioLpValue: 'drilldownPortfolio.lp_value',
      drilldownPortfolioLpPrincipal: 'drilldownPortfolio.lp_principal',
      drilldownPortfolioMajorCondition: 'drilldownPortfolio.major_condition',
    }),
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    submitLpShareCode () {
      this.result = ''
      LpPortfoliosApi.patchLpShareCode( this.drilldownPortfolio ).then(resp => {
        this.result = resp
      })
    },
  },
}
</script>
